<template>
    <v-container>
       <v-form class="my-6" id="tenant-form" enctype="multipart/form-data">
       
        <p v-if="tenant.id"><strong>Tenant ID: {{ tenant.id }}</strong></p>
        <v-text-field outlined label="Name" v-model="tenant.name"></v-text-field>
        <v-text-field outlined label="Pfad" @keyup="slugify" @blur="checkPath" v-model="tenant.path"></v-text-field>
        
        <ul class="theme-config">
            <li v-for="prop of Object.keys($store.state.ui.theme)" :key="prop">
                <span>{{ prop }}:</span>  <input type="color" v-model="tenant.theme[prop]">
            </li>
        </ul>

        <v-file-input v-if="typeof tenant.logo === 'object'" outlined v-model="tenant.logo" label="Logo"></v-file-input>
        <v-text-field v-if="typeof tenant.logo === 'string'" outlined readonly clearable v-model="tenant.logo" label="Logo" prepend-icon="mdi-image"></v-text-field>
        <v-select 
            v-if="tenant.numForecasts === 0" 
            v-model="firstYear" 
            :items="fiscalYears"
            outlined 
            class="mb-4"
            label="Erstes Forecast-Jahr" 
            hint="Wir legen für das hier ausgewählte Jahr einen ersten Forecast an."
            persistent-hint
        ></v-select>
        <v-select 
            v-if="tenant.numUsers === 0" 
            v-model="firstUser" 
            :items="users"
            outlined 
            class="mb-4"
            label="Erster Benutzer mit Zugriff auf diesen Mandanten" 
            hint="Diese Person ist die einzige Person, die direkt nach dem Anlegen Zugriff auf diesen Mandanten hat, und nur sie kann weitere Benutzer hinzufügen und Anpassungen vornehmen."
            persistent-hint
        ></v-select>

        <v-checkbox v-model="tenant.vis" @change="needsPath" label="diesen Mandanten verwenden"></v-checkbox>

            <v-row class="mt-4">
                <v-spacer></v-spacer>
                <v-btn tpe="button" to="./" text color="primary">{{ trl('cancel') }}</v-btn>
                <v-btn tpe="submit" @click.prevent="onSubmit" color="primary">{{ trl('save') }}</v-btn>
            </v-row>


    </v-form>


      

    </v-container>
</template>

<script>
import { trl } from "@/utils/strings";
export default {
  data() {
    return {
        tenant: {
            name: null,
            path: "",
            logo: null,
            theme: this.$store.state.ui.theme,
            numUsers: 0,
            numClients: 0,
            numDivisions: 0,
            numForecasts: 0,
            numAttributes: 0

        },
        users: [],
        firstUser: this.$store.state.me.id,
        firstYear: new Date().getFullYear()
    };
  },
  props: ["adminData"],
  computed: {
    fiscalYears(){
        let arr = [2020];
        for (let i = arr[0]; i < (new Date().getFullYear()) + 3; i++) arr.push(i)
        return arr;
      }
  },
  methods:{
    trl, 
    slugify(){
        this.tenant.path = this.tenant.path.replace(/\W+/g, "-").replace(/[-]+$/, "").toLowerCase();
    },
    async onSubmit(){
       
        if (!this.tenant) {
            return;
        }

        if (typeof this.tenant.logo === "object" && this.tenant.logo !== null) {
            
            // upload file and get the uploaded file's name
            const data = new FormData();
            data.append("file", this.tenant.logo);
            data.append("dir", "uploads/tenants");

            const res = await fetch('/api/upload', {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + this.$store.state.me.token,
                    'Forecast-Version': process.env.VUE_APP_BUILD
                },
                body: data
            });

            if (!res.ok) {
                this.$store.commit("toggleSnackbar", {show: true, text: res.status + " | " + res.statusText, color: "error", timeout: 5000})
                return;
            }
            const json = await res.json();
            this.tenant.logo = json[0].name_srv;
        }


        if (this.tenant.numForecasts === 0) {
            this.tenant.firstYear = this.firstYear;
        }

        if (this.tenant.numUsers === 0) {
            this.tenant.firstUser = this.firstUser;
        }

        this.tenant.vis = this.tenant.vis ? 1 : 0;
       
        await this.$store.dispatch("req", {
            route: "tenant",
            data: this.tenant,
        });

        this.$root.$emit('refreshAdminData');
        this.$router.push({name: 'Tenants'});
    },
    needsPath(){
        if (this.tenant.vis && !this.tenant.path) {
            this.tenant.vis = false;
            this.$store.commit("alert", {title: "Kein Pfad angegeben", text: "Der Mandant benötigt einen URL-Pfad!"})

        }
    },
    checkPath(){
        const pathsInUse = this.adminData.tenants.filter(t => t.path && t.id !== this.tenant.id).map(t => t.path);
        if (pathsInUse.includes(this.tenant.path)) {
            this.$store.commit("alert", {title: "Pfad wird bereits verwendet", text: `Bitte wähle einen anderen Pfad, da "${this.tenant.path}" bereits von einem anderen Mandanten verwendet wird.`})
            this.tenant.path = null;
        }
    }
  },
  async created() {

    let requested = this.adminData.tenants.find(
      (t) => t.id === this.$route.params.id
    );

    let label = "Neuer Mandant";
    if (requested) {
        this.tenant = JSON.parse(JSON.stringify(requested));
        label = this.tenant.name;
    }

    if (!this.tenant.theme) this.tenant.theme = this.$store.state.ui.theme;

    for (let prop of Object.keys(this.$store.state.ui.theme)) {
       this.tenant.theme[prop] = this.tenant.theme[prop] || this.$store.state.ui.theme[prop]
    }

    let breadcrumbs = this.$route.matched;
    breadcrumbs.at(-1).meta.label = label;
    this.$root.$emit('messageFromChild', breadcrumbs);

    this.$store.commit("setTheme", this.tenant.theme);

    this.users = [];
    this.adminData.users
            .sort((a,b) => a.userName.localeCompare(b.userName))
            .forEach(u => {
                this.users.push({text: u.userName, value: u.id})
            })

  },
  beforeRouteLeave (to, from, next) {
        this.$store.commit("setTheme");
        next();
    },
  watch: {
    // 'tenant.logo': function(val){
    //
    // } 
  }
};
</script>

<style lang="scss">
.theme-config{
    margin-bottom: 2rem;
    padding:1rem 0;
    border:1px solid #9E9E9E;
    border-radius:6px;
    background-color: white;
    column-count: 2;
    li{
        padding:.25em;
        display: block;

        span{
            display:inline-block;
            width:200px;
        }

    }

}
</style>